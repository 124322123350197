<template>
  <div class="flex flex-col gap-6">
    <TextField type="text" label="Emergency Contact Name" placeholder="Employee's emergency contact name" :borderEnabled="true" v-model="contactName" :disabled="props2 === 'Detail'" />
    <TextField type="text" label="Relationship" placeholder="Relationship with employee" :borderEnabled="true" v-model="contactRelation" :disabled="props2 === 'Detail'" />
    <TextField
      type="text"
      label="Emergency Contact Phone Number"
      placeholder="Employee's emergency contact phone number"
      :borderEnabled="true"
      v-model="contactPhone"
      :disabled="props2 === 'Detail'"
    />
    <TextField type="text" label="Emergency Contact Address" placeholder="Employee's emergency contact address" :borderEnabled="true" v-model="contactAddress" :disabled="props2 === 'Detail'" />
  </div>
</template>

<script>
export default {
  props: {
    props1: {
      type: Object,
      default: () => ({})
    },
    props2: {
      type: String
    },
    contactName() {
      return this.props1.contactName || this.props2 === 'Edit' || this.props2 === 'Create New' ? this.props1.contactName : 'Data unavailable'
    },
    contactRelation() {
      return this.props1.contactRelation || this.props2 === 'Edit' || this.props2 === 'Create New' ? this.props1.contactRelation : 'Data unavailable'
    },
    contactPhone() {
      return this.props1.contactPhone || this.props2 === 'Edit' || this.props2 === 'Create New' ? this.props1.contactPhone : 'Data unavailable'
    },
    contactAddress() {
      return this.props1.contactAddress || this.props2 === 'Edit' || this.props2 === 'Create New' ? this.props1.contactAddress : 'Data unavailable'
    }
  },
  components: {
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField')
  }
}
</script>

<style></style>
