var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex flex-col gap-6"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": "Emergency Contact Name",
      "placeholder": "Employee's emergency contact name",
      "borderEnabled": true,
      "disabled": _vm.props2 === 'Detail'
    },
    model: {
      value: _vm.contactName,
      callback: function callback($$v) {
        _vm.contactName = $$v;
      },
      expression: "contactName"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Relationship",
      "placeholder": "Relationship with employee",
      "borderEnabled": true,
      "disabled": _vm.props2 === 'Detail'
    },
    model: {
      value: _vm.contactRelation,
      callback: function callback($$v) {
        _vm.contactRelation = $$v;
      },
      expression: "contactRelation"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Emergency Contact Phone Number",
      "placeholder": "Employee's emergency contact phone number",
      "borderEnabled": true,
      "disabled": _vm.props2 === 'Detail'
    },
    model: {
      value: _vm.contactPhone,
      callback: function callback($$v) {
        _vm.contactPhone = $$v;
      },
      expression: "contactPhone"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Emergency Contact Address",
      "placeholder": "Employee's emergency contact address",
      "borderEnabled": true,
      "disabled": _vm.props2 === 'Detail'
    },
    model: {
      value: _vm.contactAddress,
      callback: function callback($$v) {
        _vm.contactAddress = $$v;
      },
      expression: "contactAddress"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }